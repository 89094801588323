<template>
  <div>
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div class="col-md-4 d-flex align-items-center">
        <a
            href="/"
            class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
        >
          <svg class="bi" width="30" height="24">
            <use xlink:href="https://rousseaufournier.com"></use>
          </svg>
        </a>
        <span class="mb-3 mb-md-0 text-body-secondary">© 2023 - {{new Date().getFullYear()}} Nicolas Fournier</span>
      </div>

      <div class="col-md-4 d-flex align-items-center">
        <span id="actualState" class="mb-3 mb-md-0 text-body-secondary"></span>
      </div>

      <!--ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3" id="btnSwitch">💡/🌑</li>
      </ul-->
    </footer>

    <div
        id="cb-cookie-banner"
        class="alert alert-dark text-center mb-0"
        role="alert"
        style="display: none;"
    >
      🍪 Ce site Web utilise des cookies pour vous garantir la meilleure expérience
      sur notre site Web.
      <a href="https://www.cookiesandyou.com/" target="_blank">Apprendre encore plus</a>
      <button
          type="button"
          class="btn btn-primary btn-sm ms-3"
          @click="hideCookieBanner"
      >
        J'ai compris
      </button>
    </div>
    </div>

</template>

<script>
export default {
  name: "Footer",
  methods: {
    initializeCookieBanner() {
      const isCookieAccepted = localStorage.getItem("cb_isCookieAccepted");
      if (!isCookieAccepted) {
        localStorage.setItem("cb_isCookieAccepted", "no");
        this.showCookieBanner();
      } else if (isCookieAccepted === "no") {
        this.showCookieBanner();
      }
      this.setTheme(localStorage.getItem("data-bs-theme") || "light");
    },
    showCookieBanner() {
      const cookieBanner = document.getElementById("cb-cookie-banner");
      if (cookieBanner) cookieBanner.style.display = "block";
    },
    hideCookieBanner() {
      localStorage.setItem("cb_isCookieAccepted", "yes");
      const cookieBanner = document.getElementById("cb-cookie-banner");
      if (cookieBanner) cookieBanner.style.display = "none";
    },
    setTheme(theme) {
      document.documentElement.setAttribute("data-bs-theme", theme);
    },
    toggleTheme() {
      const currentTheme = document.documentElement.getAttribute("data-bs-theme");
      const newTheme = currentTheme === "dark" ? "light" : "dark";
      this.setTheme(newTheme);
      localStorage.setItem("data-bs-theme", newTheme);
    },
  },
  mounted() {
    this.initializeCookieBanner();
    const btnSwitch = document.getElementById("btnSwitch");
    if (btnSwitch) {
      btnSwitch.addEventListener("click", this.toggleTheme);
    }
  },
};
</script>

<style>
#cb-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-radius: 0;
}
</style>

/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
const mutations = {
  SET_PROJECT(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.projects = data.data.Data.map(v => ({...v, Closed: v["State"]=="Fermé" }));
    }
  },
  SET_RATETYPE(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.rateTypes = data.data.Data;
    }
  },
  SET_MAINWORKTYPE(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.mainWorkTypes = data.data.Data;
    }
  },
  SET_USERS(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.users = data.data.Data;
    }
  },
  SET_STOCKS(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.stocks = data.data.Data;
    }
  },
  ADD_NEW_ROW(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      state.rows.push(data.data.Data)
    }
  },
  ADD_NEW_STOCKSHEET(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      state.stockSheets.push(data.data.Data)
    }
  },
  SET_ROWS(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.rows = data.data.Data;
    }
  },
  SET_STATEWEEKS(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.stateWeeks = data.data.Data;
    }
  },
  SET_STOCKSHEET(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.stockSheets = data.data.Data;
    }
  },
  SET_WEEK(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.week = data.data.Data;
    }
  },
  LOGIN(state, data) {
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined){
      state.actualUser = data.data.Data.Title;
      state.user = data.data.Data;
      state.actualUserId = data.data.Data.ID;
      state.actualUserAdmin = data.data.Data.admin;
    }
  },
  DISCONNECT(state) {
      state.actualUser = "";
      state.actualUserId = -1;
      state.actualUserAdmin = false;
  },
  UPDATE_ROW(state,data) {
    //Force update if error
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      const updatedRow = data.data.Data
      const index = state.rows.findIndex(r => r.ID == updatedRow.ID)
      console.log(index)
      /*if (index>=0){
        state.rows[index] = updatedRow
      }*/
    }
  },
  UPDATE_STOCKSHEET(state,data) {
    //Force update if error
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      const updatedRow = data.data.Data
      const index = state.stockSheets.findIndex(r => r.ID == updatedRow.ID)
      console.log(index)
      /*if (index>=0){
        state.rows[index] = updatedRow
      }*/
    }
  },
  DELETE_ROW(state,data) {
    //Force update if error
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      const deletedRow = data.data.Data
      const i = state.rows.map(item => item.ID).indexOf(deletedRow.ID);
      state.rows.splice(i, 1);
    }
  },
  DELETE_STOCKSHEET(state,data) {
    //Force update if error
    if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
      const deletedRow = data.data.Data
      const i = state.stockSheets.map(item => item.ID).indexOf(deletedRow.ID);
      state.stockSheets.splice(i, 1);
    }
  }
}

export default mutations

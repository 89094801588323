/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/

const state = {
  projects:[
    {
      ID:0,
      Title:"No data...",
      State:"",
      Code:"",
      Customer:"",
      Closed:false,
      ApprovedByUserID:0,
    }
  ],
  actualUser : "",
  user : {admin:false},
  actualUserId:-1,
  actualUserAdmin:false,
  stocks:[
    {
      ID:0,
      StockCode:"No data...",
      Description:"",
      StockClass:"",
      ReportClass:"",
      TaxCategory:"",
      Unit:"",
      Price:0.0,
      State:"",
      IDTemplate:"",
      PriceClass:"",
      ProductType:"",
      Disable: false
    }
  ],
  rateTypes:[
    {
      ID:0,
      Title:"No data...",
      Code:"",
      Disable: false
    }
  ],
  mainWorkTypes:[
    {
      ID:0,
      Title:"No data...",
      Code:"",
      Disable: false
    }
  ],
  users:[
    {
      ID:0,
      Title:"No data...",
      Code:"",
      RateTypeID:"",
      MainWorkTypeID:"",
      TypeTS:"",
      Approbateur2:"",
      ApprovedByUserID:0,
      Admin:false,
    }
  ],
  rows : [
    {
      ID : -1,
      user : 0,
      project : "",
      week_number : "",
      mainWorkType : "",
      rateType : "RG",
      sunday: 0.0,
      monday: 0.0,
      tuesday: 0.0,
      wednesday: 0.0,
      thursday: 0.0,
      friday: 0.0,
      saturday: 0.0,
      consume: 0.0,
      description : "",
      approved: false,
      selected: false,
      approvedBy: null ,
      task: "",
    }
  ],
  stockSheets : [
    {
      ID : -1,
      user : 0,
      project : "",
      week_number : "",
      StockCode : "",
      sunday: 0.0,
      monday: 0.0,
      tuesday: 0.0,
      wednesday: 0.0,
      thursday: 0.0,
      friday: 0.0,
      saturday: 0.0,
      consume: 0.0,
      description : "",
      selected: false,
    }
  ],
  week : {
    ID : 0,
    week_number: "",
    comment: "",
    user: 0,
    state: "",
    approvedBy: null,
  },
  stateWeeks : [{
    ID : 0,
    week_number: "",
    comment: "",
    user: 0,
    state: "",
    approvedBy: null,
  }
  ]
}

export default state

/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
import config from '@/assets/config.json'
// eslint-disable-next-line no-undef
import axios from "@/axios.js"

const actions = {
    fetchProjects({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/project")
                .then((response) => {
                    commit('SET_PROJECT', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchRateTypes({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/rate-type")
                .then((response) => {
                    commit('SET_RATETYPE', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchMainWorkType({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/main-work-type")
                .then((response) => {
                    commit('SET_MAINWORKTYPE', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchUser({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/user")
                .then((response) => {
                    commit('SET_USERS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchStock({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/stock")
                .then((response) => {
                    commit('SET_STOCKS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchTimeSheet({commit}, {code, weekNumber}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/TimeSheet/" + code + "/" + weekNumber)
                .then((response) => {
                    commit('SET_ROWS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchStateWeek({commit}, { weekNumber}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/TimeSheetPerWeek/" + weekNumber)
                .then((response) => {
                    commit('SET_STATEWEEKS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    lockStateWeek({commit, state}, { weekNumber}) {
        return new Promise((resolve, reject) => {
            axios.post(config.baseUrl + "/v1/TimeSheetPerWeek/" + weekNumber + "/" + state.actualUserId )
                .then((response) => {
                    commit('SET_STATEWEEKS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchStockSheet({commit}, {code, weekNumber}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/StockSheet/" + code + "/" + weekNumber)
                .then((response) => {
                    commit('SET_STOCKSHEET', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchTimeSheetWeek({commit}, {code, weekNumber}) {
        return new Promise((resolve, reject) => {
            axios.get(config.baseUrl + "/v1/TimeSheetWeek/" + code + "/" + weekNumber)
                .then((response) => {
                    commit('SET_WEEK', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    login({commit},
        payload
    ){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise((resolve, reject) => {
            axios.get(`/v1/me`, payload)
                .then((response) => {
                    commit('LOGIN', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    disconnect({commit}
    ){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise(() => {
            commit('DISCONNECT')
        })
    },
    updateStateTimeSheetWeek({commit}, {
        ID,
        payload
    }){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise((resolve, reject) => {
            axios.put(`/v1/TimeSheetWeek/`+ID, payload)
                .then((response) => {
                    commit('SET_WEEK', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    updateRow({commit}, {
        ID,
        payload
    }){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise((resolve, reject) => {
            axios.put(`/v1/TimeSheet/`+ID, payload)
            .then((response) => {
                commit('UPDATE_ROW', response)
                resolve(response)
            }).catch((error) => { reject(error) })
        })
    },
    updateStockSheet({commit}, {
        ID,
        payload
    }){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise((resolve, reject) => {
            axios.put(`/v1/StockSheet/`+ID, payload)
            .then((response) => {
                commit('UPDATE_STOCKSHEET', response)
                resolve(response)
            }).catch((error) => { reject(error) })
        })
    },
    addNewRow({commit},
        payload
    ){
        return new Promise((resolve, reject) => {
            axios.post(`/v1/TimeSheet/`, payload)
                .then((response) => {
                    commit('ADD_NEW_ROW', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    addNewStockSheet({commit},
        payload
    ){
        return new Promise((resolve, reject) => {
            axios.post(`/v1/StockSheet/`, payload)
                .then((response) => {
                    commit('ADD_NEW_STOCKSHEET', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    deleteRow({commit}, {
        ID,
        payload
    }){
        return new Promise((resolve, reject) => {
            axios.delete(`/v1/TimeSheet/`+ID, payload)
                .then((response) => {
                    commit('DELETE_ROW', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    deleteStockSheet({commit}, {
        ID,
        payload
    }){
        return new Promise((resolve, reject) => {
            axios.delete(`/v1/StockSheet/`+ID, payload)
                .then((response) => {
                    commit('DELETE_STOCKSHEET', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
}

export default actions

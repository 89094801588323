<template>
  <div id="timeStateToPrint" v-if="showModal">
    <!-- Modal -->
    <div class="modal fade show" style="display: block;" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title">
              État des feuilles de temps pour la semaine du {{ actualWeek }} en date du {{ new Date().toLocaleString() }}
            </h5>
            <button type="button" class="btn-close  d-print-none hide-print" aria-label="Fermer" @click="close"></button>
          </div>

          <!-- Modal Body -->
          <div class="modal-body" v-if="actualWeek !== ''">
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead class="">
                <tr>
                  <th
                      @click="sortBy('code')"
                      :class="sortKey === 'code' ? 'table-active' : ''"
                  >
                    Code d'employé
                  </th>
                  <th
                      @click="sortBy('name')"
                      :class="sortKey === 'name' ? 'table-active' : ''"
                  >
                    Nom
                  </th>
                  <th
                      @click="sortBy('state')"
                      :class="sortKey === 'state' ? 'table-active' : ''"
                  >
                    Status
                  </th>
                  <th
                      @click="sortBy('approvedBy')"
                      :class="sortKey === 'approvedBy' ? 'table-active' : ''"
                  >
                    Approuvé Par
                  </th>
                  <th
                      @click="sortBy('comment')"
                      :class="sortKey === 'comment' ? 'table-active' : ''"
                  >
                    Commentaire
                  </th>
                  <th
                      @click="sortBy('lockBy')"
                      :class="sortKey === 'lockBy' ? 'table-active' : ''"
                  >
                    Transféré Par
                  </th>
                  <th
                      @click="sortBy('UpdatedAt')"
                      :class="sortKey === 'UpdatedAt' ? 'table-active' : ''"
                  >
                    Date de MàJ
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="week in weekStatusLocal" :key="week.name">
                  <td>{{ week.code }}</td>
                  <td>{{ week.name }}</td>
                  <td :style="styleRow(week)">{{ week.state }}</td>
                  <td>{{ $store.getters.getUserTitleByID(week.approvedBy) }}</td>
                  <td>{{ week.comment }}</td>
                  <td>{{ $store.getters.getUserTitleByID(week.lockBy) }}</td>
                  <td>{{ week.UpdatedAt }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer d-print-none hide-print" >
            <button class="btn btn-secondary hide-print" @click="close">Fermer</button>
            <button class="btn btn-primary hide-print" @click="print">Imprimer</button>
            <button class="btn btn-info hide-print" @click="sortKey = ''">RAZ Triage</button>
            <button
                v-if="$store.state.actualUserAdmin"
                class="btn btn-warning hide-print"
                @click="$emit('lockApprovedWeek')"
            >
              Transféré toutes les feuilles de temps Approuvés de la semaine
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Backdrop -->
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "WeekState",
  props: {
    showModal : Boolean,
    actualWeek : String,
    userList : Array,
  },
  data(){
    return {
      sortKey: '',
      reverse: false,
      style : 'footer {\n' +
            '  font-size: 9px;\n' +
            '  color: #f00;\n' +
            '  text-align: center;\n' +
            '}\n' +
            '\n' +
            '@page {\n' +
            '  size: A4;\n' +
            '  margin: 11mm 17mm 17mm 17mm;\n' +
            '  @bottom-right {\n' +
            '    content: counter(page) " / " counter(pages);\n' +
            '  }\n' +
            '}\n' +
            '\n' +
            '@media print {\n.' +
              'hide-print {\n' +
              '    display: none;\n' +
            '  }\n' +
            '\n' +
            '  footer {\n' +
            '    position: fixed;\n' +
            '    bottom: 0;\n' +
            '  }\n' +
            '\n' +
            '  .content-block {\n' +
            '    page-break-inside: avoid;\n' +
            '  }\n' +
            '\n' +
            '  html, body {\n' +
            '    width: 210mm;\n' +
            '    height: 297mm;\n' +
            '  }\n' +
            '.pagebreak {\n' +
            '        clear: both;\n' +
            '        page-break-after: always;\n' +
            '    }'+
            '}',
        style2: 'body {\n' +
            '  width:100% !important;\n' +
            '  margin:0 !important;\n' +
            '  padding:0 !important;\n' +
            '  line-height: 1.45;\n' +
            '  font-family: Garamond,"Times New Roman", serif;\n' +
            '  color: #000;\n' +
            '  background: none;\n' +
            '  font-size: 14pt; }\n' +
            '\n' +
            '/* Headings */\n' +
            'h1,h2,h3,h4,h5,h6 { page-break-after:avoid; }\n' +
            'h1{font-size:19pt;}\n' +
            'h2{font-size:17pt;}\n' +
            'h3{font-size:15pt;}\n' +
            'h4,h5,h6{font-size:14pt;}\n' +
            '\n' +
            '\n' +
            'p, h2, h3 { orphans: 3; widows: 3; }\n' +
            '\n' +
            'code { font: 12pt Courier, monospace; }\n' +
            'blockquote { margin: 1.2em; padding: 1em;  font-size: 12pt; }\n' +
            'hr { background-color: #ccc; }\n' +
            '\n' +
            '/* Images */\n' +
            'img {\n' +
            '  float: left;\n' +
            '  margin: 1em 1.5em 1.5em 0;\n' +
            '  width: auto;\n' +
            '  max-height: 150px;\n' +
            '}\n' +
            'a img { border: none; }\n' +
            '\n' +
            '/* Links */\n' +
            'a:link, a:visited { background: transparent; font-weight: 700; text-decoration: underline;color:#333; }\n' +
            'a:link[href^="http://"]:after, a[href^="http://"]:visited:after { content: " (" attr(href) ") "; font-size: 90%; }\n' +
            '\n' +
            'abbr[title]:after { content: " (" attr(title) ")"; }\n' +
            '\n' +
            '/* Don\'t show linked images  */\n' +
            'a[href^="http://"] {color:#000; }\n' +
            'a[href$=".jpg"]:after, a[href$=".jpeg"]:after, a[href$=".gif"]:after, a[href$=".png"]:after { content: " (" attr(href) ") "; display:none; }\n' +
            '\n' +
            '/* Don\'t show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate */\n' +
            'a[href^="#"]:after, a[href^="javascript:"]:after {content: "";}\n' +
            '\n' +
            '/* Table */\n' +
            'table { margin: 1px; text-align:left; border-collapse: collapse;}\n' +
            'th { border-bottom: 1px solid #333;  font-weight: bold; }\n' +
            'td { border-bottom: 1px solid #333; }\n' +
            'th,td { padding: 4px 10px 4px 0; }\n' +
            'tfoot { font-style: italic; }\n' +
            'caption { background: #fff; margin-bottom:2em; text-align:left; }\n' +
            'thead {display: table-header-group;}\n' +
            'img,tr {page-break-inside: avoid;}\n'
    }
  },
  computed:{
    weekStatusLocal() {
      let data = Object.assign([], this.$store.getters['getStateWeeks'])

      const uniqueUser = [...new Set(data.map(item => item.user))]

      for (const user of this.$store.getters.getUsers) {
        const approvedBy = this.$store.getters.getUserById(user.ApprovedByUserID) || {ID:0,Title : ""}
        if (!uniqueUser.includes(user.ID)){
          data.push({
            ID : -1,
            week_number: this.actualWeek,
            comment: "",
            user: user.Code,
            name: user.Title,
            code: user.Code,
            state: "Aucune feuille de temps",
            approvedBy: approvedBy.ID || null ,
          })
        } else {
          let index = data.findIndex(x=>x.user==user.ID)
          if (index!==-1){
            data[index].name= user.Title
            data[index].code= user.Code
            if (data[index].approvedBy==null) {
              data[index].approvedBy=approvedBy.ID
            }
          }
        }
      }

      const isUndefined = (o,k) => o===undefined || k===undefined || k==="" || !(k in o)
      const key = this.sortKey
      if (this.sortKey==="" ){
        data = data.sort((a, b)=> {
          if (a.code === b.code){
            if (a.state === b.state){
              return a.name < b.name ? -1 : 1
            } else {
              return a.state < b.state ? -1 : 1
            }
          } else {
            return a.code < b.code ? -1 : 1
          }
        })
      } else {
        if (this.reverse) {
          data = data.sort((a, b)=> {return isUndefined(b,key) - isUndefined(a,key) || b[key] < a[key] ? -1 : 1})
        } else {
          data = data.sort((a, b)=> {return isUndefined(a,key) - isUndefined(b,key) || a[key] < b[key] ? -1 : 1})
        }

      }

      return data
    },
  },
  methods: {
    sortBy: function(sortKeynew) {
      if (sortKeynew===undefined)
        return
      this.reverse = (this.sortKey === sortKeynew) ? ! this.reverse : false;
      this.sortKey = sortKeynew;
    },
    close() {
      this.$emit('close')
    },
    styleRow(row){
      if (row.state=="Soumis")
        return "color:violet"

      if (row.state=="Refusé")
        return "color:orange"

      if (row.state=="Approuvé")
        return "color:green"

      if (row.state=="Aucune feuille de temps")
        return "color:red"

      if (row.state=="Transféré")
        return "color:blue"


        return "color:black"
    },
    print() {
      let a = window.open('', '_' );
      a.document.write('<html>');
      a.document.write('' +
          '<head>\n' +
          '  <meta charset="utf-8">\n' +
          '  <title>Feuille de temps - Rapport '+this.actualWeek+'</title>' +
          '</head>');
      a.document.write('<style>'+this.style+'</style>');
      a.document.write('<style>'+this.style2+'</style>');
      a.document.write('<body class="letter">');
      a.document.write( document.querySelector('#timeStateToPrint').innerHTML);
      a.document.write('</body>');
      a.document.write('</html>');
      a.document.close();
      a.print();
    }
  },
}
</script>

<style scoped>


</style>
<template>

  <div id="app">
    <header>
      <Navbar :user="user" />
    </header>
    <TimeSheet/>
    <PasswordModal />
    <Footer />
  </div>
</template>

<script>
import TimeSheet from './components/TimeSheetV2.vue'
import Navbar from "./components/Navbar.vue";
import PasswordModal from "./components/PasswordModal.vue";
import Footer from "./components/Footer.vue";

export default {
  name: 'App',
  components: {
    TimeSheet,
    Navbar,
    PasswordModal,
    Footer
  },
  data() {
    return {
      user : {}
    }
  },
  mounted() {
    this.$store.dispatch("login").then((data)=>{
      this.user =  data.data.Data
    })
  }
  }
</script>

<style>
#app {

}
</style>
/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
import axios from 'axios'

const domain = "http://localhost:8080/"

export default axios.create({
  domain,
  mode: "no-cors",
  baseURL:"/api",
  headers : {
    'Access-Control-Allow-Origin': '*',
  }
})

import Vue from 'vue'
import App from './App.vue'
/*
* TODO
*  - Page d'option et vue log
*  - Import CSV
*  - Projet + CP 1,2,3
*  - Vrai gestion des états de la feuille de temps (apprové vs user) - Manque que pour les timeentry et les primes
*  - Rate tableau pour les calculs de temps (avec multiplicateur)
*  - Send Mail sur CRON à tester
*/
Vue.config.productionTip = false

import axios from "./axios.js"
Vue.prototype.$http = axios
//import VueAxios from 'vue-axios'
Vue.use(axios)


import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// Vuex Store
import store from './store/store'
Vue.prototype.$store = store;
//Toast
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast);


new Vue({
  store,
  render: h => h(App),
}).$mount('#app')

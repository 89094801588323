<template>
  <div
      class="modal fade"
      id="passwordModal"
      tabindex="-1"
      aria-labelledby="passwordModalLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="passwordModalLabel">
            Changer de mot de passe
          </h5>
          <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
          ></button>
        </div>
        <form action="/api/v1/change-password"  method="post">
          <div class="modal-body">
            <div class="mb-3 row">
              <label for="old_password" class="control-label">Mot de passe actuel</label>
              <input required id="old_password" class="form-control"  type="password" name="old_password">
            </div>
            <div class="mb-3 row">
              <label for="new_password" class="control-label">Nouveau mot de passe</label>
              <input required id="new_password" minlength="10" autocomplete="off" class="form-control" type="password" name="new_password">
            </div>
            <div class="mb-3 row">
              <label for="confirm_password" class="control-label">Confirmation du nouveau mot de passe</label>
              <input required id="confirm_password" minlength="10"  autocomplete="off" class="form-control" type="password" name="confirm_password">
            </div>
          </div>
          <div class="modal-footer">
            <button href="#" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            <button type="submit" href="#" class="btn btn-primary" id="password_modal_save">Sauvegarder</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordModal",
};
</script>

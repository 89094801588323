<template>
  <div id="timeSheetToPrint" class="page" v-show="false">

    <div class="content">

    <!-- Each sheet element should have the class "sheet" -->
    <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
      <div>

        <!-- Header -->
        <img style="width: 40%" :src="userCompanyLogoUrl">

        <h1>Feuille de temps TS{{String(week.ID).padStart(4, '0')}}</h1>
        <hr>
        <h4>Nom: {{userName}}</h4>
        <h4>Date: {{actualWeek}} {{firstDay}} au {{lastDay}}</h4>
        <hr>
        <p>Status: {{week.state}}</p>
        <p v-if="week.approvedBy">Approuvé: {{$store.getters.getUserById(week.approvedBy).Title }}</p>
        <p v-if="week.comment">Commentaire: {{week.comment}}</p>
        <hr>
      </div>
      <!-- Summation Table -->
      <div class="content-block ">
        <table   style="width: 100%;

         " >
          <colgroup>
            <col span="1" style="width: 2%;">
            <col span="1" style="width: 8%;">
            <col span="1" style="width: 10%;">
            <col span="1" style="width: 10%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 30%;">
          </colgroup>
          <thead>
            <th>#</th>
            <th>Type de revenue</th>
            <th>Projet</th>
            <th>Article de main d'oeuvre</th>
            <th>Dimanche</th>
            <th>Lundi</th>
            <th>Mardi</th>
            <th>Mercredi</th>
            <th>Jeudi</th>
            <th>Vendredi</th>
            <th>Samedi</th>
            <th>Total</th>
            <th>Description</th>
          </thead>
          <tbody>
            <template>
              <tr v-for="(row, index) in rowsTimeSheetRow" :key="index">
                  <td>{{parseInt(index)+1}}</td>
                  <td>{{row.rateType}}</td>
                  <td>{{row.project}}</td>
                  <td>{{row.mainWorkType}}</td>
                  <td style="text-align: center;">{{parseFloat(row.sunday || 0 ).toFixed(2) }}</td>
                  <td style="text-align: center;">{{parseFloat(row.monday || 0 ).toFixed(2)}}</td>
                  <td style="text-align: center;">{{parseFloat(row.tuesday || 0 ).toFixed(2)}}</td>
                  <td style="text-align: center;">{{parseFloat(row.wednesday || 0).toFixed(2) }}</td>
                  <td style="text-align: center;">{{parseFloat(row.thursday || 0 ).toFixed(2)}}</td>
                  <td style="text-align: center;">{{parseFloat(row.friday || 0 ).toFixed(2)}}</td>
                  <td style="text-align: center;">{{parseFloat(row.saturday || 0 ).toFixed(2)}}</td>
                  <td style="text-align: center;"><b>{{parseFloat( $store.getters.getRowConsumeSum(row) || 0).toFixed(2)}}</b></td>
                  <td style="text-align: left;">{{row.description}}</td>
              </tr>
            </template>
          </tbody>
          <tfoot slot="footer">
            <td><b>Total</b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <!-- Total Per Day  -->
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("sunday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("monday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("tuesday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("wednesday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("thursday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("friday","*") }}</b></td>
            <td style="text-align: center;"><b>{{ $store.getters.getRowsSumPerDay("saturday","*") }}</b></td>
           <!-- Grand Total -->
            <td style="text-align: center;"><b>{{
                $store.getters.getRowsTotalSum()
              }}</b></td>
            <td><b></b></td>
          </tfoot>
        </table>
      </div>

      <hr><br class="page_content__print_break pagebreak">
      <!-- Entry Table -->

      <div class="content-block ">
        <h1>Prime</h1>
        <span>{{ userName }}-{{actualWeek}}</span>
        <hr>
        <table   style="width: 100%;

         " >
          <colgroup>
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 15%;">
            <col span="1" style="width: 15%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 5%;">
            <col span="1" style="width: 25%;">
          </colgroup>
          <thead>
          <th>#</th>
          <th>Projet</th>
          <th>ID Stock</th>
          <th>Dimanche</th>
          <th>Lundi</th>
          <th>Mardi</th>
          <th>Mercredi</th>
          <th>Jeudi</th>
          <th>Vendredi</th>
          <th>Samedi</th>
          <th>Total</th>
          <th>Description</th>
          </thead>
          <tbody>
          <template>
            <tr v-for="(row, index) in stocksSheetLocal" :key="index">
                <td style="text-align: left;">{{parseInt(index)+1}}</td>
                <td style="text-align: left;">{{row.project}}</td>
                <td style="text-align: left;">{{row.StockCode}}</td>
                <td style="text-align: center;">{{parseFloat(row.sunday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.monday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.tuesday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.wednesday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.thursday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.friday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(row.saturday).toFixed(2)}}</td>
                <td style="text-align: center;">{{parseFloat(1*(row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)).toFixed(2)}}</td>
              <td style="text-align: left;">{{row.description}}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <hr>
      <div>
        <h4>Signature : ____________________________________</h4>
        <h4>Date: ____________________________________</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimePrint",
  data() {
    return {
      style : 'footer {\n' +
        '  font-size: 9px;\n' +
        '  color: #f00;\n' +
        '  text-align: center;\n' +
        '}\n' +
        '\n' +
        '@page {\n' +
        '  size: A4;\n' +
        '  margin: 11mm 17mm 17mm 17mm;\n' +
        '  @bottom-right {\n' +
        '    content: counter(page) " / " counter(pages);\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        '@media print {\n' +
        '  footer {\n' +
        '    position: fixed;\n' +
        '    bottom: 0;\n' +
        '  }\n' +
        '\n' +
        '  .content-block {\n' +
        '    page-break-inside: avoid;\n' +
        '  }\n' +
        '\n' +
        '  html, body {\n' +
        '    width: 210mm;\n' +
        '    height: 297mm;\n' +
        '  }\n' +
        '.pagebreak {\n' +
        '        clear: both;\n' +
        '        page-break-after: always;\n' +
        '    }'+
        '}',
      style2: 'body {\n' +
        '  width:100% !important;\n' +
        '  margin:0 !important;\n' +
        '  padding:0 !important;\n' +
        '  line-height: 1.45;\n' +
        '  font-family: Garamond,"Times New Roman", serif;\n' +
        '  color: #000;\n' +
        '  background: none;\n' +
        '  font-size: 14pt; }\n' +
        '\n' +
        '/* Headings */\n' +
        'h1,h2,h3,h4,h5,h6 { page-break-after:avoid; }\n' +
        'h1{font-size:19pt;}\n' +
        'h2{font-size:17pt;}\n' +
        'h3{font-size:15pt;}\n' +
        'h4,h5,h6{font-size:14pt;}\n' +
        '\n' +
        '\n' +
        'p, h2, h3 { orphans: 3; widows: 3; }\n' +
        '\n' +
        'code { font: 12pt Courier, monospace; }\n' +
        'blockquote { margin: 1.2em; padding: 1em;  font-size: 12pt; }\n' +
        'hr { background-color: #ccc; }\n' +
        '\n' +
        '/* Images */\n' +
        'img {\n' +
        '  float: left;\n' +
        '  margin: 1em 1.5em 1.5em 0;\n' +
        '  width: auto;\n' +
        '  max-height: 150px;\n' +
        '}\n' +
        'a img { border: none; }\n' +
        '\n' +
        '/* Links */\n' +
        'a:link, a:visited { background: transparent; font-weight: 700; text-decoration: underline;color:#333; }\n' +
        'a:link[href^="http://"]:after, a[href^="http://"]:visited:after { content: " (" attr(href) ") "; font-size: 90%; }\n' +
        '\n' +
        'abbr[title]:after { content: " (" attr(title) ")"; }\n' +
        '\n' +
        '/* Don\'t show linked images  */\n' +
        'a[href^="http://"] {color:#000; }\n' +
        'a[href$=".jpg"]:after, a[href$=".jpeg"]:after, a[href$=".gif"]:after, a[href$=".png"]:after { content: " (" attr(href) ") "; display:none; }\n' +
        '\n' +
        '/* Don\'t show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate */\n' +
        'a[href^="#"]:after, a[href^="javascript:"]:after {content: "";}\n' +
        '\n' +
        '/* Table */\n' +
        'table { margin: 1px; text-align:left; border-collapse: collapse;}\n' +
        'th { border-bottom: 1px solid #333;  font-weight: bold; }\n' +
        'td { border-bottom: 1px solid #333; }\n' +
        'th,td { padding: 4px 10px 4px 0; }\n' +
        'tfoot { font-style: italic; }\n' +
        'caption { background: #fff; margin-bottom:2em; text-align:left; }\n' +
        'thead {display: table-header-group;}\n' +
        'img,tr {page-break-inside: avoid;}\n'
    }
  },
  props: {
    actualWeek: {
      type: String,
      required: true,
    },
    firstDay: {
      type: String,
      required: true,
    },
    lastDay: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    userPrintName: {
      type: String,
      required: true,
    },
    userCompanyLogoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    stocksSheetLocal() {
      return Object.assign([], this.$store.getters['getStockSheets'])
    },
    rowsTimeSheetRow() {
      return Object.assign([], this.$store.getters['getRows'])
    },
    week() {
      return Object.assign([], this.$store.getters['getWeek'])
    },
  },
  methods: {
    print() {
      var a = window.open('', '_' );
      a.document.write('<html>');
      a.document.write('' +
        '<head>\n' +
        '  <meta charset="utf-8">\n' +
        '  <title>'+'Feuille de temps' + ' - ' + this.userName + ' - ' + this.dateISOToUse+'</title>' +
        '</head>');
      a.document.write('<style>'+this.style+'</style>');
      a.document.write('<style>'+this.style2+'</style>');
      a.document.write('<body class="letter">');
      a.document.write( document.querySelector('#timeSheetToPrint').innerHTML);
      a.document.write('</body>');
      a.document.write('</html>');
      a.document.close();
      a.print();
    }
  },
  mounted() {

  }
}
</script>
<style scoped>
td {
  padding: 0px !important;
}
td input {
  margin: 0;
  height: 100%;
  display: inline-block;
  width: 100%;
  padding: 0;
}
</style>

<template>
  <div class="table-container" >
    <header class="header">
      <div class="btn-group" role="group" aria-label="Basic example">

      <!-- Bouton Actualiser -->
      <button
          type="button"
          class="btn btn-primary position-relative"
          @click="loadWeekData"
          :disabled="!canEdit"
          title="Actualiser">
        Actualiser
      </button>
      <!-- Bouton Ajouter une ligne -->
      <button
          type="button"
          class="btn btn-success position-relative"
          @click="addRow"
          :disabled="!canEdit"
          title="Ajouter une ligne">
        Ajouter une ligne
      </button>
      <!-- Bouton Exporter vers Excel -->
      <button
          type="button"
          class="btn btn-primary position-relative"
          @click="toCSV"
          :disabled="$store.getters.getStockSheets.length === 0"
          title="Exporter vers Excel">
        Exporter vers Excel
      </button>
      <!-- Bouton Supprimer les lignes -->
      <button
          type="button"
          class="btn btn-danger position-relative"
          @click="deleteRows"
          :disabled="!canEdit || $store.getters.getStockSheets.filter(r => r.selected === true).length === 0"
          title="Supprimer les lignes">
        Supprimer les lignes
      </button>
      <button
          type="button"
          class="btn btn-warning position-relative"
          @click="sommaireTab"
          title="Voir Temps">
        Temps
      </button>
      <button
          type="button"
          class="btn btn-primary position-relative"
          :disabled="true"
          title="Voir Prime">
        Prime
        <span v-if="$store.getters.getStockSheets.length>0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{$store.getters.getStockSheetsTotal()}}
            </span>
      </button>
      </div>

    </header>
    <!-- Tableau -->
    <div class="table-responsive">
      <table style="margin-bottom: 300px;" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>🗑️</th>
        <th style="min-width: 150px">Projet</th>
        <th>Client</th>
        <th>Description du projet</th>
        <th style="min-width: 150px">ID stock</th>
        <th style="min-width: 350px">Description</th>
        <th>U/M</th>
        <th>Dim</th>
        <th>Lun</th>
        <th>Mar</th>
        <th>Mer</th>
        <th>Jeu</th>
        <th>Ven</th>
        <th>Sam</th>
        <th>Qté totale</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in $store.getters.getStockSheets" v-bind:key="row.ID">
        <td>
          <input :disabled="!canEditRow(row)" type="checkbox" v-model="row.selected">
        </td>
        <td>
          <div v-if="canEditRow(row)">
            <v-select
                style="height: 100%;padding: 0;"
                v-model="row.project"
                :clearable="false"
                :reduce="(option) => option.value"
                :options="$store.getters.getProjectOptions"
                @input="editRow(row)"
            >
              <template #option="{ label  }">
                <p style="margin: 0">{{ label }}</p>
              </template>
              <template #selected-option="{ label, value }">
                <div style="display: flex; align-items: baseline">
                  <strong>{{ value }}</strong>
                </div>
              </template>
            </v-select>
          </div>
          <div v-else>
            {{row.project}}
          </div>
        </td>
        <td>{{ $store.getters.getProjectCustomerByCode(row.project) }}</td>
        <td>{{ $store.getters.getProjectTitleByCode(row.project) }}</td>
        <td><div v-if="canEditRow(row)">
          <v-select
              style="height: 100%;padding: 0;"
              v-model="row.StockCode"
              :clearable="false"
              :reduce="(option) => option.value"
              :options="$store.getters.getStockOptions"
              @input="editRow(row)"
          >
            <template #option="{ label  }">
              <p style="margin: 0">{{ label }}</p>
            </template>
            <template #selected-option="{ label, value }">
              <div style="display: flex; align-items: baseline">
                <strong>{{ value }}</strong>
              </div>
            </template>
          </v-select>
        </div>
          <div v-else>
            {{row.StockCode}}
          </div></td>
        <td class="full-td">
          <div v-if="canEditRow(row)">
                <textarea  type="text"
                           rows="4"
                           cols="50"
                           class="full-td-input"
                           :disabled="!canEditRow(row)"
                           @change="editRow(row)"
                           @keydown="editRow(row)"
                           v-model="row.description"/>
          </div>
          <div v-else>
            {{row.description}}
          </div>
        </td>
        <td>{{$store.getters.getStockUnitByStockCode(row.StockCode)}}</td>
        <td class="full-td">
          <input class="input full-td-input" type="number"  :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="sunday"  v-model.number="row.sunday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="monday"  v-model.number="row.monday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="tuesday" v-model.number="row.tuesday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="wednesday" v-model.number="row.wednesday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="thursday" v-model.number="row.thursday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="friday" v-model.number="row.friday" style="width: 100%;height: 100%">
        </td>
        <td class="full-td">
          <input class="input full-td-input" type="number" :disabled="!canEditRow(row)" @focus="$event.target.select()" @change="editRow(row)" step="0.25" name="saturday" v-model.number="row.saturday" style="width: 100%;height: 100%">
        </td>
        <td>{{parseFloat(sumConsume(row)).toFixed(2)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
import arrayToCSV from "@/arrayToCSV";
import 'debounce';



let debounce = require('debounce');

export default {
  name: "Equipment",
  components:{

  },
  props: {
    actualTab: Number,
    actualUserId: Number,
    actualWeek: String,
    canEdit: Boolean,
  },
  data() {
      return {
        saving:false,
        projectColumns:[
          { title: 'Code', data: 'Code' },
          { title: 'Titre', data: 'Title' },
          { title: 'État', data: 'State' },
        ],
        /*projectFilteredList:null,*/
        projectSearch:null,
    }
  },
  computed:{
    projectFilteredList() {
      if (this.projectSearch === undefined || this.projectSearch === null || this.projectSearch === ""  ) {
       return  this.$store.getters.getProjectOptions;
      }
      else {
        return this.$store.getters.getProjectOptions.filter(x=>x.label.toString().toUpperCase().includes(this.projectSearch.toString().toUpperCase()))
      }
    }
  },
  methods:{

    canEditRow(row) {
      return this.canEdit && row.ID!=-1
    },
    sommaireTab() {
      this.$emit('sommaireTab')
    },
    loadWeekData() {
      this.$emit('loadWeekData')
    },
    isNanDay(row, day){
      if (row[day]=="" || isNaN(row[day])) {
        row[day]=0;
      }
      return row
    },
    editRow: debounce(function(row) {
      if (this.canEditRow(row) && row.ID!=-1){
        if (row.project===null) {
          row.project=""
        }
        row = this.isNanDay(row,"sunday")
        row = this.isNanDay(row,"monday")
        row = this.isNanDay(row,"tuesday")
        row = this.isNanDay(row,"wednesday")
        row = this.isNanDay(row,"thursday")
        row = this.isNanDay(row,"friday")
        row = this.isNanDay(row,"saturday")
        this.saving=true
        this.$store.dispatch("updateStockSheet", {ID : row.ID,payload : row}).then(
            ()=>{this.saving=false}
        ).catch((err)=>{
          console.log(err)
          this.saving=false
          this.$toast.error("Une erreur est survenue!")
        })
      }
    }, 50),
    addRow() {
      if (!this.canEdit) {
        return;
      }
      this.saving=true
      this.$store.dispatch("addNewStockSheet",{
        week_number:this.actualWeek,
        user:this.actualUserId,
        project:"",
      }).then(
          ()=>{this.saving=false}
      ).catch((err)=>{
        console.log(err)
        this.$toast.error("Une erreur est survenue!")
      })
    },
    toCSV(){
      let csv = arrayToCSV({ data:this.$store.getters.getStockSheets})
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
      element.setAttribute('download', this.actualUserId+" "+this.actualWeek+".csv");
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    deleteRows(){
      if (!this.canEdit) {
        return;
      }
      if (this.$store.getters.getStockSheets.length===0){
        return;
      }
      if (confirm('Êtes-vous sur de supprimer ces lignes?')){
        this.$store.getters.getStockSheets.filter(r=>r.selected===true).forEach((row)=> {
          if (row.selected) {
            this.saving=true
            this.$store.dispatch('deleteStockSheet', {ID:row.ID,payload : row}).then(
                ()=>{this.saving=false}
            ).catch((err)=>{
              console.log(err)
              this.$toast.error("Une erreur est survenue!")
            })
          }
        })
      }
    },
    sumConsume(row) {
      return 1*(row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday);
    }
  }
}
</script>

<style scoped>
.summary-row div {
  flex: 1;
}

.summary-row div input {
  width: 80%;
}



.time-summary div {
  flex: 1;
  text-align: center;
}
/* Tableau */




.toolsBtn {
  padding: 10px 15px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
}

.toolsBtn[disabled] {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.toolsBtn:hover:not([disabled]) {
  background-color: #0056b3;
}

.blinking{
  animation:blinkingText 2s infinite;
}

.full-td {
  position: relative;
  min-width: 100px;
}

.full-td-input{
  position: absolute;
  display: block;
  top:0;
  left:0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
}
.table > tbody > tr > td {
  vertical-align: middle;
}
@keyframes blinkingText{
  0%{     color: red;    }
  49%{    color: red; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: red;    }
}
</style>